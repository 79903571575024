import React from 'react'
import { ContatoStyle } from './style'
import {Link} from 'react-router-dom'
import {IoMdMail} from 'react-icons/io'
import {FaPhoneSquareAlt} from 'react-icons/fa'
import {ImLocation2} from 'react-icons/im'
import emailjs from 'emailjs-com';


function Contato(){

  function sendEmail(e){

    e.preventDefault();

    emailjs.sendForm('service_d9q1xop', 'template_ckur1ye', e.target, 'user_XqJx7Opd0YYtj9qvbz9h8')
      .then((result)=>{
        alert('Menssagem enviada com sucesso');
      }, (error) =>{
        alert('Error tente novamente')
      });
      e.target.reset()
  }

return (<ContatoStyle>

  
  
  <div className='conteiner-form'>
    <div className='form'>
    <h2>ENTRE EM CONTATO</h2>
    <form onSubmit={sendEmail}>
 
      <input type="text" name="nome" id="nome" placeholder=' Nome:'/>
      <fieldset>

        <input type="tel" name="telefone" id="telefone" placeholder=' Telefone:'/>
        
        <input type="email" name="email" id="email" placeholder=' E-mail:'/>
      </fieldset>

      <fieldset>

   
              <input type="text" name="marca" id="name" placeholder=' Marca:'/>
 
              <input type="text" name="modelo" id="name" placeholder =' Modelo:'/>

      </fieldset>     
              
              
              <select name="equipamento" id="equipamento">
                <option selected>Equipamentos</option>
                <option value="Projetor">Projetor</option>
                <option value="Placa De Video">Placa de Video</option>
                <option value="Placa Mãe">Placa Mãe</option>
                <option value="Notebook">Notebook</option>
              </select>
              
              
              
              <textarea name="areatexto" id="ateatexto" cols="20" rows="20" placeholder='Descreva Seu Defeito:'></textarea>

              <input type="submit" value="Enviar" />

            </form>
  </div>
    <div className='info'>

      <div className='form-info'>

        <IoMdMail/>
        <div className='form-contatos'>
          <a href="mailto:suporte@towertech.net.br">suporte@towertech.net.br</a>
          <p>Responderemos em breve</p>
        </div>  
     
      </div>

      <div className='form-info'>

      <FaPhoneSquareAlt/>
      <div className='form-contatos'>
      <p>Nosso Contato</p>
      <div><a href="tel:+559238771719">(92)3877-1719</a></div>
       
      </div>  

      </div>

      <div className='form-info'>

        <ImLocation2/>
        <div className='form-contatos'>
          <p><strong>Rua:Vivaldo Lima, 01-Alvorada I</strong></p>
          <p>(Proximo ao SPA do Alvorada)</p>
        </div>  

      </div>  

        

    </div>
  </div>

</ContatoStyle>)


}

export default Contato