/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import HomeStyle from './style'
import { MdComputer } from 'react-icons/md'
import { GiComputerFan } from 'react-icons/gi'
import { RiProjector2Line } from 'react-icons/ri'
import { BsGearFill } from 'react-icons/bs'
import { GiCctvCamera } from 'react-icons/gi'
import { RiComputerFill } from 'react-icons/ri'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'
import lenovo from '../imagem/l.jpg'

import Dell from '../imagem/dell.png'
import Sony from '../imagem/s.png'
import Nec from '../imagem/n.png'
import { Link } from 'react-router-dom'





function Home() {







  return (
    <HomeStyle>

      <div className="banner">
        <div className='conteiner'>
          <h2>EXCELÊNCIA EM MANUTENÇÃO E REPARO EM PROJETORES E EQUIPAMENTOS DE INFORMÁTICA </h2>

          <p>Projetores / Notebook / Solda BGA / Recuperação de Placas </p>
          <Link to='/Contato'>Solicite um Orçamento</Link>

        </div>
      </div>


      <div className="section1">
        <h3>Assistência Técnica</h3>

        <div className="conteiner">

          <div className="section1-info">
            <RiProjector2Line />
            <h4>Projetores</h4>
            <p>Fazemos manutenção, reparos, instalação de projetores de todas as marcas, e montagem de espaços para projeções.</p>
            <Link to='/Assistencia/Projetores'>[Saiba mais]</Link>
          </div>

          <div className="section1-info">
            <GiCctvCamera />
            <h4>Câmeras</h4>
            <p>Oferecemos serviços de manutenção, substituição e instalação de câmeras e infraestrutura necessária para o funcionamento dos mais variados sistemas de segurança eletrônica CFTV.</p>
            <Link to='/Assistencia/Camera'>[Saiba mais]</Link>
          </div>


          <div className="section1-info">
            <MdComputer />
            <h4>Notebooks</h4>
            <p>Realizamos Formatação, limpeza, reparos diversos, configuração, tudo para deixar seu notebook rápido e funcional.</p>
            <Link to='/Assistencia/Notebooks'>[Saiba mais]</Link>
          </div>

          <div className="section1-info">
            <RiComputerFill />
            <h4>PC Gamer</h4>
            <p>Montamos e fazemos Upgrade das mais variadas configurações de computadores Gamers, entusiastas e High-End. </p>
            <Link to='/Assistencia/Gamer'>[Saiba mais]</Link>
          </div>

          <div className="section1-info">
            <BsGearFill />
            <h4>Placa Mãe</h4>
            <p>Analisamos os mais complexos circuitos em placas mãe de desktops, notebooks, placas lógicas de projetores e placas eletrônicas.</p>
            <Link to='/Assistencia/PlacaMae'>[Saiba mais]</Link>
          </div>

          <div className="section1-info">
            <GiComputerFan />
            <h4>Placa De Video</h4>
            <p>Fazemos substituição de solda BGA de chips gráficos de placas de vídeos e chipsets com equipamentos específicos para realização do serviço.</p>
            <Link to='/Assistencia/PlacaDeVideo'>[Saiba mais]</Link>
          </div>



        </div>


        <h3>Somos especializados em</h3>

        <div className="sessao1-imagens">
          <div>



            <img src={acer} alt="acer.app" />

            <img src={beng} alt="beng.app" />

            <img src={Dell} alt="dell.app" />

            <img src={epson} alt="epson.app" />

            <img src={lenovo} alt="lenovo.app" />

            <img src={Nec} alt="nec.app" />


            <img src={Sony} alt="Sony.app" />

            <img src={view} alt="view.app" />

          </div>
        </div>

      </div>

      <div className='mini-banner'>
        <div className='conteiner'>
          <h4>Dispomos da melhor estrutura do Norte do país</h4>
          <p>Confie em quem realmente é especialista, com experiência e excelência nos serviços prestados em Assistência Técnica.</p>
        </div>
        <div className='info-banner'>
          <Link to='/Contato'>Solicite um Orçamento</Link>
        </div>
      </div>



      <div className='maps'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15936.212933831732!2d-60.0349709!3d-3.0804652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4208cfb0beafd0d!2sTower%20Tech%20-%20Assist%C3%AAncia%20t%C3%A9cnica%20em%20projetores%2C%20computadores%20e%20notebooks.!5e0!3m2!1spt-BR!2sbr!4v1628089029586!5m2!1spt-BR!2sbr"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"></iframe>
      </div>

    </HomeStyle>
  )

}

export default Home;