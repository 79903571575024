import styled from 'styled-components';


export const FooterArea = styled.div`
background-color: #21a5dd;
width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: #fff;

.conteiner{
  
  margin-top: 50px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    width: 280px;
    height: auto;
    

    img{
      width: 200px;
      margin-bottom: 20px;
    }

    h4{
      color:#f3f3ff;
    }

    input{
      width:255px;
    }
    p{
      margin: 2px;
    }
    select{
      width: 94%;
      height: 25px;
      margin: 10px 0px;
      border-radius:2px;
    }
    textarea{
      height:100px;
    }
  }
  .footer-info{
    
    iframe{
      width: 250px;
      height: 250px;
    }
  }
  .footer-logo{
    text-align: center;
    margin-bottom: 90px;
  }

  @media(max-width:900px){
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
}


`
