import React from 'react'
import { SobreStyle } from './style'
import {Link} from 'react-router-dom'
import loja from '../imagem/loja.jpeg'
import loja2 from '../imagem/loja2.jpeg'
import loja3 from '../imagem/loja3.jpeg'
import loja4 from '../imagem/loja4.jpeg'
import loja5 from '../imagem/loja5.jpeg'
import loja6 from '../imagem/loja6.jpeg'



function Sobre(){

  return(
    <SobreStyle>

      <div className='section'>

      <div className='conteiner'>

        <div className='info-section1'>
          <h2>Quem Somos</h2>

          <p><strong>A TOWER TECH </strong>atua em Manaus desde 2016 no mercado local. Oferecemos serviços de recuperação e manutenção de projetores, notebooks, desktops, placas de vídeo, placas eletrônicas e sistemas de câmeras de segurança.</p>
          <br />
          <p>A equipe Tower Tech é formada por técnicos treinados, especializados e registrados. Nossa infraestrutura conta com um laboratório completo composto por equipamentos modernos cujas análises acontecem de forma detalhada para os mais diversificados e complexos consertos, de forma segura, rápida, eficiente e eficaz.</p>
          <br />
        </div>

        <img src={loja} alt="" />
      

      </div>

      <div className='conteiner reverse'>

        <div className='info-section1'>
          <h2>Nossa Empresa</h2>
          
          <h3>Missão</h3>
          <p>Criar e estabelecer vínculos fortes e duradouros com nossos clientes, apresentando soluções tecnológicas e prestando-lhes serviços com padrão de alta qualidade.</p>
          <br />
          <h3>Visão</h3>
          <p>Ser referência no segmento de assistências técnicas na cidade de Manaus garantindo a qualidade e satisfação em benefício dos clientes.</p>
          <br />
          <h3>Valores</h3>
          <p>Apoiamos valores importantes para o desenvolvimento da empresa e das pessoas como integridade, transparência, ética, respeito, comprometimento e inovação.</p>
          <br/>
        </div>

        <img src={loja2} alt="loja-maquinas" />
      

      </div>
      <br/>

      <section className='section-fotos'>

        <article>

          <img src={loja3} alt="portaria-towetech" />
          <img src={loja4} alt="acessorios" />
          <img src={loja5} alt="ferramentas" />
          <img src={loja6} alt="ferramentas" />

        </article>

      </section>

      </div>

    </SobreStyle>
    )

}

export default Sobre;