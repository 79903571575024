import React from 'react'
import {ProjetorStyle} from './style'
import {Link} from 'react-router-dom'
import {BiCheck} from 'react-icons/bi'
import projetor from '../imagem/projetor.png'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'

function Projetor(){

  return (
    <ProjetorStyle>

      

      <div className='section'>
    

        <div className='conteiner'>

          <div className='list'>
          <h2>Manutenção Em Projetores</h2>
          <p>
          Com um suporte técnico especializado em Projetores e fornecedores de peças de reposição de primeira linha das principais marcas do mercado, a TOWER TECH oferece uma manutenção de altíssima qualidade com garantia tanto do serviço prestado quanto das peças de reposição utilizadas na manutenção do seu Projetor.
          </p>
          <ul>
            <li><BiCheck/>Manutenção preventiva e corretiva </li>
            <li><BiCheck/>Limpeza técnica e revisão geral</li>
            <li><BiCheck/>Troca de lâmpadas com defeito</li>
            <li><BiCheck/>Conserto e troca de fontes queimadas</li>
            <li><BiCheck/>Reparo túnel de espelho</li>
            <li><BiCheck/>Alinhamento e convergência de LCD's</li>
            <li><BiCheck/>Reparo de placa eca controller</li>
            <li><BiCheck/>Instalação de projetores</li>
            <li><BiCheck/>Fornecemos lâmpadas para projetor</li>
            <li><BiCheck/>Substituição de Chip DMD e troca de Collor Wheel</li>
          </ul>
        </div>
          <img src={projetor} alt="projetor.app" className='projetor' />
        </div>
          
      </div>


    </ProjetorStyle>
  )

}

export default Projetor