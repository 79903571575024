import React, {useEffect, useState} from 'react';
import {BrowserRouter, Switch, Route,Link} from 'react-router-dom'
import './App.css';
import Home from './pages/Home'
import Sobre from './pages/Sobre';
import Contato from './pages/Contato'
import Projetor from './pages/Projetores'
import Notebooks from './pages/Notebook'
import Camera from './pages/Cameras'
import Placas from './pages/Placas'
import Gamer from './pages/Gamer'

import Header from './components/partials/Header/index'
import Footer from './components/partials/Footer/index'

function App(){

  return(
  <BrowserRouter>
    <Header/>

    <Switch>

      <Route  exact  path="/">
        <Home/>
      </Route>
      <Route path ='/Sobre'>
        <Sobre/>
      </Route>
      <Route path ='/Assistencia/Projetores'>
      <Projetor/>
      </Route>
      <Route path ='/Assistencia/Notebooks'>
      <Notebooks/>
      </Route>
      <Route path ='/Assistencia/Camera'>
      <Camera/>
      </Route>
      <Route path ='/Assistencia/Placas'>
      <Placas/>
      </Route>

      <Route path ='/Assistencia/Gamer'>
      <Gamer/>
      </Route>

      <Route path ='/Contato'>
        <Contato/>
      </Route>

    </Switch>

    
    <Footer/>

  </BrowserRouter>
  )
 
}


export default App
