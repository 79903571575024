/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';
import { FooterArea } from './style';
import banner from '../../../imagem/banner.png'
import { render } from '@testing-library/react';
import emailjs from 'emailjs-com';

export default class App extends Component {




  render() {

    function sendEmail(e) {

      e.preventDefault();

      emailjs.sendForm('service_d9q1xop', 'template_ckur1ye', e.target, 'user_XqJx7Opd0YYtj9qvbz9h8')
        .then((result) => {
          alert('Menssagem enviada com sucesso');
        }, (error) => {
          alert('Error tente novamente')
        });
    }

    return (
      <FooterArea>

        <div className="conteiner">
          <div className='footer-logo'>
            <img src={banner} alt="logoapp" />
            <p>A TOWER TECH atua em Manaus desde 2016 como assistência técnica, oferecendo serviços de recuperação de equipamentos eletrônicos, manutenção de projetores e recuperação de placas eletrônicas</p>
          </div>
          <div className='footer-info'>
            <h4>NOSSA LOJA</h4>
            <p><strong>Rua Vivaldo Lima nº 01 - Alvorada I, Manaus - AM, 69043-660</strong></p>
            <p>Tel: (92) 3877-1719</p>
            <p>Email: suporte@towertech.net.br</p>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15936.212933831732!2d-60.0349709!3d-3.0804652!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4208cfb0beafd0d!2sTower%20Tech%20-%20Assist%C3%AAncia%20t%C3%A9cnica%20em%20projetores%2C%20computadores%20e%20notebooks.!5e0!3m2!1spt-BR!2sbr!4v1628089029586!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"></iframe>
          </div>
          <div className='footer-form'>
            <h4>ENTRE EM CONTATO</h4>
            <form onSubmit={sendEmail}>
              <p>Seu Nome:</p>
              <input type="text" name='nome' />
              <p>Telefone:</p>
              <input type="tel" name='telefone' />
              <p>Seu Email:</p>
              <input type="email" name='email' />
              <select name='equipamento' >
                <option selected>Equipamento</option>
                <option value="Projetor">Projetor</option>
                <option value="Placa De Video">Placa de Video</option>
                <option value="Placa Mãe">Placa Mãe</option>
                <option value="Notebook">Notebook</option>
              </select>
              <p>Marca:</p>
              <input type="text" name='marca' />
              <p>Modelo:</p>
              <input type="text" name='modelo' />


              <p>Descreva o defeito:</p>
              <textarea name="areatexto" id="ateatexto" cols="30" rows="10"></textarea>

              <input type="submit" value="enviar" />

            </form>
          </div>

        </div>
        <div >
          <p>Trend © 2021. Todos os Direitos Reservados.</p>
        </div>
      </FooterArea>
    )
  }

}


