import React, { useState } from 'react';
import { HeaderArea, Nav } from './style';
import { ImWhatsapp } from 'react-icons/im'
import { GrInstagram, GrMail } from 'react-icons/gr'
import { FaPhoneAlt } from 'react-icons/fa'
import { AiOutlineDown } from 'react-icons/ai'
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import Logo from './logo.png'

const Header = () => {

  const [menu, setMenu] = useState(-200)


  function botaoAction() {
    if (menu == 300) {
      setMenu(-250)
    } else {
      setMenu(300)
    }
  }

  return (

    <HeaderArea >
      <Nav left={menu + 'px'} onClick={botaoAction}>
        <div></div>
        <div></div>
        <div></div>
        <ul>
          <div>
            <span className='span1'></span>
            <span className='span2'></span>
          </div>

          <li><Link to='/'>Home</Link></li>
          <li><Link to='/sobre'>Sobre</Link></li>
          <li><Link to='/Assistencia/Projetores'>Projetores</Link></li>
          <li><Link to='/Assistencia/Camera'>Câmera</Link></li>
          <li><Link to='/Assistencia/Notebooks'>Notebooks</Link></li>
          <li><Link to='/Assistencia/Gamer'>Computadores Gamers</Link></li>
          <li><Link to='/Assistencia/Placas'>Placas</Link></li>

          <li><Link to='/Contato'>Contato</Link></li>
        </ul>
      </Nav>
      <div className="header-info">

        <div className="info-contatos">
          <a href="tel:+559238771719"> <FaPhoneAlt />(92)3877-1719</a>
          <a href="mailto:suporte@towertech.net.br"><GrMail /> suporte@towertech.net.br</a>
        </div>

        <div className="info-social">
          <a href="https://api.whatsapp.com/send?phone=5592387711719">
            <ImWhatsapp className="svg" />
          </a>
          <a href="https://www.instagram.com/_towertech/">
            <GrInstagram className="svg" />
          </a>
        </div>
      </div>

      <div className="conteiner">
        <div>
          <Link to='/'><img src={Logo} alt="logo.app" /></Link>
        </div>

        <nav>
          <ul>

            <li><Link to='/'>Home</Link></li>
            <li><Link to='/sobre'>Sobre</Link></li>
            <li className='cat-assistencia'>
              <a href=''>Assistência<AiOutlineDown /></a>
              <ul>
                <li><Link to='/Assistencia/Projetores'>Projetores</Link></li>
                <li><Link to='/Assistencia/Camera'>Câmera</Link></li>
                <li><Link to='/Assistencia/Notebooks'>Notebooks</Link></li>
                <li><Link to='/Assistencia/Gamer'>Computadores Gamers</Link></li>
                <li><Link to='/Assistencia/Placas'>Placas</Link></li>
              </ul>
            </li>
            <li><Link to='/Contato'>Contato</Link></li>

          </ul>
        </nav>
      </div>




    </HeaderArea>
  )
};

export default Header;