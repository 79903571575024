import React from 'react'
import {ProjetorStyle} from './style'
import {Link} from 'react-router-dom'
import {BiCheck} from 'react-icons/bi'
import placas from '../imagem/camera.jpeg'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'

function PlacaVideo(){

  return (
    <ProjetorStyle>

   

      <div className='section'>
    

        <div className='conteiner'>

          <div className='list'>
          <h2>Soluções Em Segurança Eletrônica E T.I</h2>
          <p>
          Nossa equipe é extremamente preparada para realizar manutenção, substituição e instalação de câmeras e infraestrutura necessária para o funcionamento dos mais variados sistemas de segurança eletrônica CFTV.
          </p>
          <ul>
            <li><BiCheck/>Intalação sistemas de câmeras de segurança CFTV</li>
            <li><BiCheck/>Manutenção preventiva e corretiva</li>
            <li><BiCheck/>Locação</li>
            <li><BiCheck/>Monitoramento eletronico em creche, escolas e universidades</li>
            <li><BiCheck/>Controle por app</li>
            <li><BiCheck/>Elaboração e execução de projetos</li>
            <li><BiCheck/>CÂMERAS SPEED - Alta perfomace e resolução, grandes distâncias, 360°</li>
            <li><BiCheck/>CÂMERAS BULLET -Alta resolução dia, noite e ambientes pouco iluminados</li>
            <li><BiCheck/>CÂMERAS DOME - Indicada para uso interno, desing discreto</li>
            <li><BiCheck/>CÂMERAS WIFI - Melhor custo benefício, interação de voz com o ambiente</li>
            <li><BiCheck/>CÂMERAS IP - Menor custo de acabamento quadidade 4k, zoom sem perda de definição</li>
        

            
          </ul>
        </div>
          <img src={placas} alt="projetor.app" />
        </div>
          
      </div>

     

    </ProjetorStyle>
  )

}

export default PlacaVideo