import styled, { keyframes } from 'styled-components'
import banner from '../imagem/notebook.jpg'

const mover = keyframes`
from{
		width: 0%;
	}
	to{
		width: 50%
	}
`
const moverh2 = keyframes`
from{

	}
	to{
    right: 0px;
    opacity: 1;
	}
`
const moverp = keyframes`
from{

	}
	to{
    margin-left: 0px;
    opacity: 1;
	}
`
const movera = keyframes`
from{

}
to{
  margin-top:350px;
  opacity: 1;
}
`



export const HomeStyle = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.sessao1-imagens{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content:center;
  align-items: center;
  overflow: hidden;
  margin-top: 50px;

div{
 
  width: 1300px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  @media(max-width:1400px){
    width: 80%;
  }
  @media(max-width:580px){
    flex-direction: column;
    justify-content: center;
  }
}  

  img{
    width: 150px;
    height: auto;
    padding: 40px;
    
  }
}

.banner{
width: 100%;
height: 600px;
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-size:cover;
background:url(${banner}); 
background-position: center;
&::after{
  content: '';
  width: 0%;
  height: 600px;
  background: #000;
  position: absolute;
  z-index: 2;
  left: 0;
  animation: ${mover} 1s ;
  animation-fill-mode: forwards;
  opacity: 0.4;
}
&::before{
  content: '';
  width: 0%;
  height: 600px;
  background: #000;
  position: absolute;
  z-index: 2;
  right: 0;
  opacity: 0.4;
  animation: ${mover} 1s ;
  animation-fill-mode: forwards;
}

.conteiner{
  position: relative;
  width: 90%;
  height: auto;
  display: flex ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;


  h2,p,a{
  
    opacity: 0;
    color: #f3f3f3;
    text-decoration: none;
    position: absolute;
    margin-bottom:100px;
    transition: 0.4s;
    text-align: center;
  }
  h2{
    width: 100%;
     right: 100px;
    animation: ${moverh2} 2s ;
  animation-fill-mode: forwards;
  }

 p{
   margin-left:500px;
   margin-top: 150px;
   animation:${moverp} 2s;
   animation-fill-mode: forwards;
 }


  a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top:500px;
    background:#21a5dd;
   padding: 20px 40px;
   border-radius: 20px;
   border: 2px solid #fff;
   animation: ${movera} 2s;
   animation-fill-mode:forwards;
   
  }
  
}

@media(max-width:450px){
  .conteiner{
    
    h2{
      margin-bottom: 200px;
    }
  }
}

}

.section1{
  width:100%;
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    position: relative;
    font-size: 1.5rem;
    margin-top:130px;

    &::before{
      content: '';
      width: 50px;
      height: 2px;
      position: absolute;
      z-index: 2;
      background: #21a5dd;
     bottom: 0;

    }
  }

  .conteiner{
    width: 80%;
    height: auto;
    display: flex;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items: center;
    .section1-info{
      margin-top: 100px;
      width: 370px;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      a{
        color:#21a5dd
      }
      svg{
        width: 40px;
        height: 40px;
        fill: #21a5dd;
        min-height:40px;
        min-width: 40px;
      }
      h4{
        color: #4b4e57;
        font-size: 1.3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 10px;
         &::before{
          content: '';
          width: 50px;
          height: 2px;
          position: absolute;
          z-index: 2;
          background: #2052c9;
          bottom: 0;
        }
      }
      a{
        text-decoration: none;
      }
      
    }
  }

.sessao1-imagens{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content:center;
  align-items: center;
  overflow: hidden;
  margin-top: 0px;

div{
 
  width: 80%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  @media(max-width:580px){
    flex-direction: column;
    justify-content: center;
  }
}  

  img{
    width: 150px;
    height: auto;
    padding: 40px;
    
  }
}

}

.mini-banner{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background:#21a5dd;
  h4{
    color:#fff;
    font-size: 1.2rem;
  }
  p{
    font-size: 1.1rem;
    color: #f3f3f3;
  }
  a{
   font-size: 1.1rem;
   color: #4b4e57;
    padding:20px 30px;
    background-color: #fff;
    border-radius: 20px;
    border:3px solid #21a5dd
  }

  @media(max-width:1000px){
    flex-direction:column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    height: 350px;

    a{
      margin-top: 20px;
    }
  }
}

.section2{
  margin-top:60px;
  margin-bottom: 30px;
  width:100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  
  h2{
    margin-bottom: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before{
      content: '';
      width: 50px;
      height: 2px;
      background: #21a5dd;
      position: absolute;
      top:40px;
    }
  }
  img{
    width: 90%;
    height: auto;
  }
}

.sessao2-imagens{
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content:center;
  align-items: center;
  overflow: hidden;

div{
 
  width: 80%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  @media(max-width:580px){
    flex-direction: column;
    justify-content: center;
  }
}  

  img{
    width: 150px;
    height: auto;
    padding: 30px;
    
  }
}

.maps{
  overflow: hidden;
  margin-top: 40px;
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
  iframe{
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

`
export const SobreStyle = styled.div`
margin-top:100px;
width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.mini-banner{
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 60px;
  p{
    display: flex;
    flex-direction: row;

  }
  a{
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size:1.1rem;
  }
 .conteiner{
   width: 80%;
   height: auto;
  
 }

 @media(max-width:600px){
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .conteiner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
      margin:0px;
    }
  }
  
 }
}

.section{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .conteiner{
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;

    h2{
      color: #2052c9;
      display: flex;
      font-size: 1.7rem;
      position: relative;
      margin-bottom: 60px;
     
     
      
    }
    p{
      font-size: 1rem;
      color: #4b4e57;
      margin: 5px;
    }

    img{
      margin-left: 40px;
      width: 570px;
      height: auto;
    }
   
  }
  .reverse{
    flex-direction: row-reverse;

    img{
      margin-left: 0px;
      margin-right: 40px;
    }
  }

  @media(max-width:1000px){
      .conteiner{
        flex-direction: column-reverse;
        
        h2{
          text-align: center;
         
          &::before{
            width: 100px;
            margin-top: 80px;
          }
        }
        img{
          width: 100%;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
}

.section-fotos{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
 

article{
  width: 80%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  img{
    width: 500px;
    height: 500px;
    margin-bottom: 20px;
  }
}

}

`
export const ContatoStyle = styled.div`
margin-top:100px;
width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

.mini-banner{
  width: 100%;
  height: 180px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  
  p{
    display: flex;
    flex-direction: row;

  }
  a{
    text-decoration: none;
    font-weight: bold;
    font-size:1.1rem;
    
  }
 .conteiner{
   width: 80%;
   height: auto;
 }

 @media(max-width:600px){
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .conteiner{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
      margin:0px;
    }
  }
  
 }
}

.conteiner-form{
  margin-bottom: 60px;
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    

   
   
   h2{
    color: #2052c9;
    display: flex;
      font-size: 1.7rem;
      position: relative;
      margin-bottom: 60px;
    
   }

    p{
      font-size: 1.4rem;
      font-weight: bold;
      color: #4b4e57;
      margin: 4px;
    }
 
    .form{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: start;
      justify-content: start;

      form{
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      fieldset{
        width: 101%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        transform: translateX(-12px);
        margin: 0px;
        
        input{
          width: 100%;
        }
      }
    }
    }
    input{
      width: 100%;
      height: 25px;
     
      &::placeholder{
        font-size: 1.1rem;
      }
    }
    textarea{
      width: 100%;
    
      &::placeholder{
        font-size: 1.1rem;
        font-weight: 300;
      }
    }
    select{
      width: 100%;
      height: 30px;
      margin-top:10px;
      border-radius: 2px;
      color: #4b4e57;
 
      &::placeholder{
        font-size: 1.1rem;
        color: #4b4e57;
      }
    }
    button{
      background:#21a5dd;
      padding: 20px;
      color: #fff;
      border: 1px solid #21a5dd;
      border-radius:5px;
      transform:translateY(10px);
      cursor: pointer;
      font-size: 1.05rem;
      &:hover{
        opacity: 0.9
      }
    }
  }

.info{
  width: 500px; 
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form-info{
    width: 100%;
    height:auto;
    display: flex;
   justify-content: center;
   align-items: center;
    padding: 20px;
    svg{
      width: 35px;
      height: 35px;
      fill: #21a5dd;
      transform: translateX(-10px);
    }
    .form-contatos{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      p{
        font-size: 0.9rem;
      }
      a{
        text-decoration: none;
        transform: translateX(5px);
        color: #21a5dd;
        &::hover{
          text-decoration:underline;
        }
      }
    }
  }
}

@media(max-width:1025px){
  .conteiner-form{

    width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .form{
        align-items: center;
        justify-content: center;

        form{
          fieldset{
            flex-wrap: wrap;
            margin-bottom: -15px;

            input{
              margin-top:10px;
            }
          }
        }
      }
      .info{
        width: 85%;
        justify-content: center;
        align-items: center;

        .form-info{
          width: 250px;
        }
        svg{
         min-width: 30px;
         min-height: 30px;
        }
      }
     
    }

  }
@media(max-width:750px){

  .conteiner-form{
  .form{
    
    form{
      
    }
    fieldset{
      flex-direction: column;
      width: 100%;

      input{
              
      }
     
    }
  }
  
}    
}

`
export const ProjetorStyle = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;

.mini-banner{
  
  width: 100%;
  height: 150px;
  display: flex ;
  align-items: center;
  justify-content: space-evenly;
  color: black;

  h1{
    color: #2052c9;

  }

 
  .conteiner-banner{
    width: 75%;
  }

  
  a{
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: bold;
    color: gray
  }

  @media(max-width:600px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .conteiner-banner{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
  h1{
    margin: 0px;
    padding-top:20px;
  

   
  }
  }
  
}

.section{
  margin-top: 50px;
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  
  .conteiner{
    width: 90%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .list{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      p{
        text-align: justify;
        font-size: 1.1rem;
        color: #4b4e57;
      }

      ul{
        list-style: none;
        margin-left: -40px;
        
        li{
          display: flex;
          align-items: center;
          font-size: 1.1rem;
          margin: 10px 0px;

          svg{
            width: 30px;
            height: 30px;
            fill: #2052c9;
          }
        }
      }
    }

    h2{
      font-size: 2rem;
      color: #2052c9;
      font-weight: bold;
      
  }

    img{
      width: 400px;
      height: auto;
    }
    .projetor{
      width: 570px;
    }
    .notebook{
      width: 500px;
      margin-left: 60px;
    }

    @media(max-width:1000px){
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      h2{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &::before{
          margin-top:90px;
        }
      }
      .notebook{
      width: 80%;
      margin-left: 0px;
    }
    .projetor{
      width: 80%;
    }
    img{
      width: 80%;
      height: auto;
    }


    }
    @media(max-width:376px){
      h2{
        &::before{
          margin-top: 120px;
        }
      }
    }

  }
}

.sessao2-imagens{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0px;
  div{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap


  }

img{
  width: 160px;
  height: auto;
  margin: 60px 0px;
}
}


`


export default HomeStyle;