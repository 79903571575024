import styled from 'styled-components';
import React,{useState} from 'react'




export const HeaderArea = styled.div`

width: 100%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
position: relative;

.header-info{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #21a5dd;


  .info-contatos{
    margin-left: 100px;
    width: 350px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;

      svg{
        width: 15px;
        height: 20px;
        margin-top: 1px;
        margin-right: 3px;
      }

    }
  
  }
  .info-social{
    margin-right:100px;
    width: 80px;
    display: flex;
    justify-content: space-between;


    .svg{
      width: 25px;
      height: 25px;
      fill: #f3f3f3;
    }
  }

}

.conteiner{
  width: 85%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

 div{
   width: 150px;
   height: auto;
   
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-right:40px;
   border-right: 2px solid #21a5dd;
    
   img{
     width: 130px;
   }
 }

  nav{
    width: 600px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    ul{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      
      li{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 40px ;
        position: relative;
        border-left: 3px solid #fff;
        transition:0.4s;
        &:hover{
          border-left: 3px solid #21a5dd;
          transition:0.4s;
          svg{
              transform: rotate(180deg);
              transition: 0.4s;
            }
            ul{
          display: flex;
          transition: 0.4s;
        }
        }
       
        a{
          display: flex;
          justify-content:space-between;
          align-items: center;
          text-decoration: none;
          color: #4b4e57;
          font-size: 1rem;
          font-weight: bold;
          transition: 0.4s;

          &:hover{
            color: #21a5dd;
            transition: 0.4s;
          }
        

          svg{
            transition: 0.4s;
            
          }
          
        }

        ul{
        background-color: #fff;
        box-shadow: 5px 7px 12px #4b4e57;
        width: 142px;
        height: 320px;
        display: none;
        flex-direction: column;
        position: absolute;
        top: 50px;
        z-index: 4;

        li{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 50px;
         
          
          a{
           margin-right: 50px;
           text-align: center;
          }

        }

        }
       
      }
    }

  }

}

@media(max-width:800px){
  .conteiner{
    justify-content: center;
    height: 300px;

    div{
      border-right: none;
      padding-right:0px;
      img{
        width: 180px;
        height: auto;
      }
    }

    nav{
      display: none;
    }

  }

}

@media(max-width:600px){
.header-info{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to left, #2052c9,#21a5dd);


  .info-contatos{
    flex-direction: column;
    margin-left: 0px;
    a{
      margin-bottom: 10px;
     
    }
  }

  .info-social{
    margin-right: 0px;
    padding-bottom: 10px;
  }

}

  

}


`
export const Nav =styled.nav`
width: 50px;
  height: 50px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  position: fixed;
  cursor: pointer;
  z-index: 10;
  top: 40px;
  right: 40px;
  

  @media(max-width:800px){
    display: flex;
  }
.section3 p{
font-size: 1rem;

}
div{
  position: relative;
  width: 40px;
  height: 6px;
  border-radius: 50px;
  background-color:black;
  margin: 3px;
}

ul{
  opacity: 1;
  position: absolute;
  top: -56px;
  left: ${props => props.left}; //-70px -430px
  z-index: 2;
  width: 900px;
  height: 100.6vh;
  background:#21a5dd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition:1s;
  cursor: default;
  div{
    background:none;
  width: 50px;
  height:50px;
  position: absolute;
  top:10px;
  left:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
span{
  width: 40px;
  height: 7px;
  border-radius: 70px;
  background-color: #fff;
  position: absolute;
}
.span1{
  transform: rotate(50deg);
}
.span2{
  transform: rotate(-50deg);
}
}
}
li{
  font-size: 1.5rem;
  font-weight:bold;
  margin:10px 0px 10px 0px;
  color: white;
  cursor: pointer;
  transition: 0.4s;
  position: relative;
  overflow: hidden;
  &::before{
    content:'';
    position: absolute;
    width: 0px;
    height: 5px;
    background-color: #fff;
    left: 0;
    bottom: 0;
  }
  &:hover:before{
    width: 150px;
    transition:0.8s
  }
  a{
    color:#fff;
    text-decoration: none;
  }

  }


span{
    width: 10px;
    height: 2;
    background-color: #f3f3f3;
    
  }
`