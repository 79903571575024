import React from 'react'
import { ProjetorStyle } from './style'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import placas from '../imagem/placas.png'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'

function PlacaMae() {

  return (
    <ProjetorStyle>



      <div className='section'>


        <div className='conteiner'>

          <div className='list'>
            <h2>Manutenção Em Placas</h2>
            <p>Temos um laboratório completo com equipamentos modernos para realizarmos análises e gravações de bios de forma rápida e eficiente. Possuímos equipamentos específicos para reparo em placas de vídeo, como máquina de solda BGA e estações de retrabalho precisas para não agredir as peças sensíveis.</p>
            <ul>
              <p><strong>Placa Mãe</strong></p>
              
              <li><BiCheck />Problemas com memória RAM  </li>
              <li><BiCheck />Conflitos de hardware</li>
              <li><BiCheck />Energia insuficiente</li>
              <li><BiCheck />Superaquecimento</li>
              <li><BiCheck />Curto-circuito</li>
              <p><strong>Placa De Video</strong></p>
              
              <li><BiCheck />Não apresenta imagem</li>
              <li><BiCheck />Riscos na tela</li>
              <li><BiCheck />Imagem distorcida</li>
              <p><strong>REBALLING</strong></p>
              <li><BiCheck />Substituição da solda do chip BGA</li>
            </ul>
          </div>
          <img src={placas} alt="placas.app" />
        </div>

      </div>

      

    </ProjetorStyle>
  )

}

export default PlacaMae