import React from 'react'
import { ProjetorStyle } from './style'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import placas from '../imagem/placas.png'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'
import pc from '../imagem/pc.png'

function PlacaMae() {

  return (
    <ProjetorStyle>



      <div className='section'>


        <div className='conteiner'>

          <div className='list'>
            <h2>Computadores Gamers</h2>
            <p>
            Contamos com profissionais capacitados para montagem, upgrade e manutenção das mais variadas configurações de computadores Gamers, entusiastas e High-End, de acordo com a necessidade de nossos clientes e com o melhor custo benefício.
            </p>
            <ul>
              <li><BiCheck />Montagem de configuração de Pc Gamer</li>
              <li><BiCheck />Manutenção preventiva e corretiva</li>
              <li><BiCheck />Montagem e instalação de water cooler selado</li>
              <li><BiCheck />Montagem e instalação de water cooler custom</li>
            </ul>
          </div>
          <img src={pc} alt="placas.app" />
        </div>

      </div>

   

    </ProjetorStyle>
  )

}

export default PlacaMae