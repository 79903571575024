import React from 'react'
import { ProjetorStyle } from './style'
import { Link } from 'react-router-dom'
import { BiCheck } from 'react-icons/bi'
import notebookImg from '../imagem/macbook.png'
import acer from '../imagem/acer.png'
import beng from '../imagem/beng.png'
import epson from '../imagem/epson.png'
import view from '../imagem/view.png'

function Notebooks() {

  return (
    <ProjetorStyle>



      <div className='section'>


        <div className='conteiner'>

          <div className='list'>
            <h2>Manutenção Em Notebooks</h2>
            <p>
            Trabalhamos com excelência na manutenção e recuperação em placas e circuitos eletrônicos, manutenções em sistema operacional, formatação, instalação de programas, limpeza, reparos diversos, configuração, tudo para deixar seu notebook rápido e funcional.
            </p>
            <ul>
              <li><BiCheck />Tela quebrada ou com defeito </li>
              <li><BiCheck />Problemas com Lentidão e Performance</li>
              <li><BiCheck />HD travando</li>
              <li><BiCheck />Problemas com o conector de energia</li>
              <li><BiCheck />Bateria não segura carga</li>
              <li><BiCheck />Problemas com o cartão de memória</li>
              <li><BiCheck />Falhas nos microfones e autofalantes</li>
              <li><BiCheck />Falhas na webcam</li>
              <li><BiCheck />Recuperação de placa-mãe</li>
            </ul>
          </div>
          <img src={notebookImg} alt="notebook.app" className='notebook' />
        </div>

      </div>

      

    </ProjetorStyle>
  )

}

export default Notebooks